import { labels } from 'src/labels/main_labels'

export const HEADER_BAR_HEIGHT = '60px'
export const FOOTER_BAR_HEIGHT = '52px'
export const DRAWER_WIDTH_OPEN = 255
export const DRAWER_WIDTH_CLOSE = 124
export const CARD_PLANNING_HEIGHT = 350
export const CARD_DISH_HEIGHT = 337
export const CARD_DISH_WIDTH = 270

export const OPEN_LAYOUT_DEFAULT = true
export const CONTAINER_WIDTH_DEFAULT = 1250

export const languages = [
  { value: 'es', text: 'español' },
  { value: 'en', text: 'inglés' }
]

export const termsAndConditionsUrl = 'https://www.metaphysio.com/terminos-y-condiciones'
export const privacyPolicy = 'https://www.metaphysio.com/politica-de-privacidad'

export const daysOfTheWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

export const statiticsOptionsSelect = [
  { value: 'days', label: 'Semana' },
  { value: 'weeks', label: '28 Dias' },
  { value: 'months', label: '12 Meses' }
]

export const genderOptionsSelect = [
  { value: 'na', label: '-' },
  { value: 'mujer', label: labels.es.Woman },
  { value: 'hombre', label: labels.es.Man }
]
