import app from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/database'
import 'firebase/compat/storage'
import 'firebase/compat/functions'
import firebaseConfig from './config'
import { getAuth, signInWithRedirect, OAuthProvider, getRedirectResult, signInWithPopup } from 'firebase/auth'

export class Firebase {
  app: app.app.App
  auth: app.auth.Auth
  db: app.firestore.Firestore
  files: app.storage.Storage
  persi: any
  functions: app.functions.Functions

  constructor() {
    app.initializeApp(firebaseConfig)
    this.app = app.app()
    this.auth = app.auth()
    this.db = app.firestore()
    this.files = app.storage()
    this.persi = getAuth()
    this.functions = app.functions()
  }

  async loginOIDC() {
    const provider = new OAuthProvider('oidc.adeo')
    const auth = getAuth()
    signInWithPopup(auth, provider)
      .then((result) => {
        // User is signed in.
        // IdP data available using getAdditionalUserInfo(result)

        // Get the OAuth access token and ID Token
        const credential = OAuthProvider.credentialFromResult(result)
        if (credential) {
          console.log(credential)
          const accessToken = credential.accessToken
          const idToken = credential.idToken
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code
        console.log(errorCode)
        alert(errorCode)

        const errorMessage = error.message
        console.log(errorMessage)
        alert(errorMessage)
      })
  }

  async login(email: string, password: string, persistence: boolean): Promise<void> {
    if (!persistence) {
      await app.auth().setPersistence(app.auth.Auth.Persistence.SESSION)
      await this.auth.signInWithEmailAndPassword(email, password)
      // .then(function (userCredential) {
      // userCredential?.user?.updateEmail('sara.balado.f@obramat.es')
      // })
    } else {
      await app.auth().setPersistence(app.auth.Auth.Persistence.LOCAL)
      await this.auth.signInWithEmailAndPassword(email, password)
      //   .then(function(userCredential) {
      //     userCredential?.user?.updateEmail("carlos-alberto.rojasb@obramat.es")
      // })
    }
  }

  async logout(): Promise<void> {
    return await this.auth.signOut()
  }
}

const firebase = new Firebase()
export default firebase
